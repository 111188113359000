import { createStore } from 'effector';
import Slide_1 from '../assets/SlidesEducation/1.jpeg';
import Slide_2 from '../assets/SlidesEducation/2.png';
import Slide_3 from '../assets/SlidesEducation/3.jpg';
import Slide_4 from '../assets/SlidesEducation/4.jpeg';
import Slide_5 from '../assets/SlidesEducation/5.jpg';
import Slide_6 from '../assets/SlidesEducation/6.jpg';
import Slide_7 from '../assets/SlidesEducation/7.png';
import Slide_8 from '../assets/SlidesEducation/8.jpg';
import Slide_9 from '../assets/SlidesEducation/9.jpg';
import Slide_10 from '../assets/SlidesEducation/10.jpg';
import Slide_11 from '../assets/SlidesEducation/11.jpg';
import Slide_12 from '../assets/SlidesEducation/12.jpg';
import Slide_13 from '../assets/SlidesEducation/13.jpeg';
import Slide_14 from '../assets/SlidesEducation/14.jpeg';

const educationSlidesSrc = [
  {
    image: Slide_1,
    title: '1. Взаимодействие с Заказчиком',
    description:
      'За каждым Заказчиком закреплен свой Специалист отдела обучения, который ответственен за сопровождение всего процесса обучения (Заключение договора, определение перечня образовательных программ, прием заявок на обучение, консультирование слушателей и их кураторов, формирование и отправка итоговых документов).',
  },
  {
    image: Slide_2,
    title: '2. Прием заявок на обучение от кураторов организаций',
    description:
      'Специалисты учебного центра принимают в работу заявки на обучение по образовательным программам, в том числе для организации проверки знаний в системе ЕИСОТ, по корпоративным курсам Заказчика.',
  },
  {
    image: Slide_3,
    title: '3. Проверка поступивших заявок',
    description:
      'Заявка автоматизированно загружается и обрабатывается в LMS, система сверяет верность заполнения данных перед формированием электронной группы. После чего, система делает рассылку с доступами обучающимся и уведомление о создании группы куратору от Заказчика.',
  },
  {
    image: Slide_4,
    title: '4. Загрузка поступивших заявок в СДО, формирование учебных групп',
    description:
      'Весь процесс обучения сопровождается в LMS, четкую систематичность и точность которой обеспечивает своя команда IT-специалистов, регулярно оказывающих тех.поддержку пользователям.',
  },
  {
    image: Slide_5,
    title: '5. Обучение с помощью новых технологий ',
    description:
      'Обучение слушателей происходит с использованием интерактивных элементов, в том числе компьютерно-имитационных и VR-тренажеров, интерактивных презентаций.',
  },
  {
    image: Slide_6,
    title: '6. Непрерывный контроль образовательного процесса',
    description:
      'Специалисты оперативно отвечают на любые вопросы слушателей и кураторов во время обучения, в том числе на вопросы по аттестации, функционалу LMS и получению документов после успешного завершения аттестации.',
  },
  {
    image: Slide_7,
    title: '7. Окончание обучения',
    description:
      'Успешное завершение обучения, рассылка кураторам предприятий, закрытие протоколов и внесение необходимых данных в федеральные реестры.',
  },
  {
    image: Slide_8,
    title: '8. Отчетность',
    description:
      'Данные об обученности вносятся в установленные сроки в федеральные реестры (ФИС ФРДО, Минтруд).',
  },
  {
    image: Slide_9,
    title: '9. Согласование итоговых документов ',
    description:
      'В регламентируемые сроки учебный центр согласовывает с кураторами корректность итоговых документов об обучении.',
  },
  {
    image: Slide_10,
    title: '10. Подготовка и подписание актов',
    description:
      'После согласования итоговых документов руководитель отдела обучения готовит акты выполненных работ по каждой организации и куратору.',
  },
  {
    image: Slide_11,
    title: '11. Отправка документов ',
    description:
      'Специалисты готовят протоколы, свидетельства, удостоверения и дипломы к отправке курьерской службой. Каждый пакет четко сгруппирован по организациям и кураторам, а трек-номер хранится до момента получения реципиентом посылки.',
  },
  {
    image: Slide_12,
    title: '12. Доставка документов ',
    description:
      'После отправки документов и накладных курьерской службой команда ожидает обратной связи от получателя.',
  },
  {
    image: Slide_13,
    title: '13. Очное обучение',
    description:
      'Ежедневно в учебном классе проводятся очные занятия по образовательным программам согласно графика.',
  },
  {
    image: Slide_14,
    title: '14. Учебный класс ',
    description:
      'Учебный класс оборудован комфортабельной мебелью, техническими средствами обучения (мультимедийным оборудованием, натурными тренажерами).',
  },
];

export const educationSlidesSrcData = createStore(educationSlidesSrc);
