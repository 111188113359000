import { createStore } from 'effector';
import KarevaV from '../assets/team/КареваВ.png';
import BitykovD from '../assets/team/ДмитрийБ.png';
import PelihovkiyD from '../assets/team/ДмитрийП.png';
import GolenY from '../assets/team/ЮлияГ.png';
import PurilovaI from '../assets/team/ИринаП.png';
import VeligockihR from '../assets/team/РоманВ.png';
import ShadrinE from '../assets/team/ЕвгенийШ.png';
import BlagodatskihV from '../assets/team/ВиталийБ.png';
import ShkrebenokV from '../assets/team/ВасилийШ.png';
import GakA from '../assets/team/ГакА.png';
import FateevaS from '../assets/team/СветланаФ.png';
import IvaschenkoA from '../assets/team/АннаИ.png';
import BitykovaE from '../assets/team/ЕкатеринаБ.png';
import MaidanovaE from '../assets/team/МайдановаЕ.png';

import GaskovaK from '../assets/team/ГаськоваК.png';
import BobritskayaN from '../assets/team/БобрицкаяН.png';
import PosadskihA from '../assets/team/ПосадскихА.png';
import ShonohovaD from '../assets/team/ШоноховаД.png';
import EpifanovaE from '../assets/team/ЕпифановаЕ.png';
import StepanovV from '../assets/team/СтепановВ.png';
import ZhiryakovE from '../assets/team/ЖиряковЕ.png';
import BendaN from '../assets/team/БендаН.png';
import NalobinS from '../assets/team/НалобинС.png';

const ourTeamSrc = [
  {
    id: 1,
    src: KarevaV,
    name: 'Валерия Карева',
    post: 'Генеральный директор',
  },
  {
    id: 2,
    src: BitykovD,
    name: 'Дмитрий Битюков',
    post: 'Коммерческий директор',
  },
  {
    id: 3,
    src: PelihovkiyD,
    name: 'Дмитрий Пелиховский',
    post: 'Руководитель отдела разработки',
  },
  {
    id: 4,
    src: PurilovaI,
    name: 'Ирина Путилова',
    post: 'Руководитель отдела обучения',
  },
  {
    id: 5,
    src: GolenY,
    name: 'Юлия Голень',
    post: 'Директор по продажам',
  },
  {
    id: 20,
    src: EpifanovaE,
    name: 'Елена Епифанова',
    post: 'Руководитель направления по организации очного обучения',
  },
  {
    id: 21,
    src: StepanovV,
    name: 'Виталий Степанов',
    post: 'Старший тренер',
  },
  {
    id: 19,
    src: ShonohovaD,
    name: 'Дарья Шонохова',
    post: 'Руководитель методического отела',
  },
  {
    id: 15,
    src: BitykovaE,
    name: 'Екатерина Битюкова',
    post: 'Методист',
  },
  {
    id: 11,
    src: GakA,
    name: 'Анастасия Гак',
    post: 'Методист',
  },
  {
    id: 12,
    src: FateevaS,
    name: 'Светлана Фатеева',
    post: 'Специалист отдела обучения',
  },
  {
    id: 13,
    src: IvaschenkoA,
    name: 'Анна Иващенко',
    post: 'Специалист отдела обучения',
  },
  {
    id: 14,
    src: MaidanovaE,
    name: 'Елена Майданова',
    post: 'Специалист отдела обучения',
  },
  {
    id: 16,
    src: GaskovaK,
    name: 'Ксения Гаськова',
    post: 'Специалист отдела обучения',
  },
  {
    id: 17,
    src: BobritskayaN,
    name: 'Наталья Бобрицкая',
    post: 'Специалист отдела обучения',
  },
  {
    id: 18,
    src: PosadskihA,
    name: 'Анастасия Посадских',
    post: 'Специалист отдела обучения',
  },
  {
    id: 6,
    src: VeligockihR,
    name: 'Роман Велигоцкий',
    post: 'Техлид',
  },
  {
    id: 8,
    src: BlagodatskihV,
    name: 'Виталий Благодатских',
    post: 'SEO специалист',
  },
  {
    id: 10,
    src: ShkrebenokV,
    name: 'Василий Шкребенок',
    post: 'Фронтенд-разработчик',
  },
  {
    id: 22,
    src: ZhiryakovE,
    name: 'Евгений Жиряков',
    post: 'Фронтенд – разработчик',
  },
  {
    id: 23,
    src: BendaN,
    name: 'Никита Бенда',
    post: 'Бэкенд – разработчик',
  },
  {
    id: 23,
    src: NalobinS,
    name: 'Святослав Налобин',
    post: 'Бэкенд – разработчик',
  },
  {
    id: 7,
    src: ShadrinE,
    name: 'Евгений Шадрин',
    post: 'Инженер по тестированию',
  },
];

export const ourTeamSrcData = createStore(ourTeamSrc);
