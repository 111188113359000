import React, { useEffect, useState } from 'react';
import styles from './EducationBlock.module.css';
import './messageAnimation.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
//* Графика
import EducationGuy from '../../assets/educationGuy.png';
import Message from '../../assets/message.png';
import AlertMesage from '../../assets/alertMessage.png';
//* Иконки
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PhonelinkOutlinedIcon from '@mui/icons-material/PhonelinkOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
//* Модалки
import ModalEducation from '../../ui/modal/ModalEducation';
import ModalELearning from '../../ui/modal/ModalELearning';
import ModalAdmin from '../../ui/modal/ModalAdmin';

const EducationBlock = () => {
  const [isMassageAnimation, setIsMassageAnimation] = useState(false);
  const [isModalOpenEducation, setIsModalOpenEducation] = useState(false);
  const [isModalOpenELearning, setIsModalOpenELearning] = useState(false);
  const [isModalOpenAdmin, setIsModalOpenAdmin] = useState(false);

  //* Хэндлеры для открытие и закрытие модалок
  const openModalEducation = () => {
    setIsModalOpenEducation(true);
  };

  const openModalELearning = () => {
    setIsModalOpenELearning(true);
  };

  const openModalAdmin = () => {
    setIsModalOpenAdmin(true);
  };

  const closeModal = () => {
    setIsModalOpenEducation(false);
    setIsModalOpenELearning(false);
    setIsModalOpenAdmin(false);
  };

  //* Юзэффект для активации анимации сообщения
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerHeight = 1600;

      if (scrollPosition > triggerHeight) {
        setTimeout(() => {
          setIsMassageAnimation(true);
        }, 1000);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={styles.root}
      id="education">
      <div className={styles.education_wrapper}>
        <ModalEducation
          isOpen={isModalOpenEducation}
          onClose={closeModal}
        />
        <ModalELearning
          isOpen={isModalOpenELearning}
          onClose={closeModal}
        />
        <ModalAdmin
          isOpen={isModalOpenAdmin}
          onClose={closeModal}
        />
        <div className={styles.education_title_mobile}>
          Организация образовательной деятельности
        </div>
        <div className={styles.info_wrapper}>
          <div className={styles.text_wrapper}>
            <div className={styles.column}>
              <div className={styles.education_title}>
                Организация образовательной деятельности
              </div>
              <PhonelinkOutlinedIcon className={styles.icons} />
              <div className={styles.title}>Процесс обучения</div>
              <div>
                С 2004 года ведёт образовательную деятельность по программам
                профессионального и дополнительного профессионального обучения
                для работников различных организаций.
                <br /> 50+ штатных сотрудников проводят обучение по 1000+ курсам
                и аттестуют 100.000+ человек ежегодно.
              </div>
              <div
                className={styles.btn_more}
                onClick={openModalEducation}>
                Как это работает?
              </div>
            </div>
            <div className={styles.column}>
              <DoneAllIcon className={styles.icons} />
              <div className={styles.title}>Администрирование</div>
              <div>
                Позволяет специалистам Югорского учебного центра осуществлять
                загрузку заявок и курировать каждого обучающегося, разрабатывать
                новые уникальные курсы и открывать к ним доступ, формировать и
                подавать данные в федеральные реестры, а также направлять
                результаты экзаменов в удобном онлайн-формате.
              </div>
              <div
                className={styles.btn_more}
                onClick={openModalAdmin}>
                Как это работает?
              </div>
            </div>{' '}
            <div className={styles.column}>
              <AssignmentIndOutlinedIcon className={styles.icons} />
              <div className={styles.title}>Обучение в LMS</div>
              <div>
                Делает процесс получения знаний интерактивным и удобным
                благодаря онлайн-тренажерам и лекциям. Правовой базой для
                каждого курса служат законодательные документы, к которым может
                обратиться обучающийся. После успешного прохождения курса
                обучающийся проходит итоговую аттестацию.
              </div>
              <div
                className={styles.btn_more}
                onClick={openModalELearning}>
                Как это работает?
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pic_wrapper}>
          <img
            className={styles.educationGuy}
            src={EducationGuy}
            alt=""
          />
          <TransitionGroup>
            {isMassageAnimation && (
              <CSSTransition
                classNames="messageAnimation"
                timeout={300}>
                <div className={styles.animationGroup}>
                  <img
                    className={styles.message}
                    src={Message}
                    alt="Message"
                  />
                  <img
                    className={styles.alertMessage}
                    src={AlertMesage}
                    alt="AlertMesage"
                  />

                  <div className={styles.textMessage}>
                    <b>Уважаемый Дмитрий!</b>
                    <br />
                    <br />
                    Вы зачислены в <br />
                    «Югорский учебный <br />
                    центр» по программам:
                    <br />
                    <br />
                    · Б.3.6 Производство <br />
                    ферросплавов <br />
                    <br />
                    · О.11.4 Охрана труда
                    <br />
                    <br />
                    Перейти к обучению: <br />
                    <a
                      className={styles.link}
                      href="https://lms.simax-it.ru/"
                      target="blank">
                      lms.simax-it.ru
                    </a>
                  </div>
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default EducationBlock;
