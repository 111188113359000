import React, { useState } from 'react';
import styles from './ModalKiosk.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Slide_1 from '../../assets/SlidesKiosk/1.jpeg';
import Slide_2 from '../../assets/SlidesKiosk/2.jpg';
import Slide_3 from '../../assets/SlidesKiosk/3.jpg';

const ModalKiosk = ({ isOpen, onClose }) => {
  const slides = [
    {
      image: Slide_1,
      title:
        '1. Разработанный компанией «Симакс» мобильный инфокиоск может быть использован для демонстрационных видеоматериалов и молний.',
    },
    {
      image: Slide_2,
      title:
        '2. Инфокиоск может быть наполнен индивидуальной информацией под конкретного заказчика.',
    },
    {
      image: Slide_3,
      title:
        '3. Каждый пользователь имеет доступ к его собственному личному прогрессу, аттестационным результатам и допуску по qr-коду.',
    },
  ];
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const showPreviousSlide = () => {
    setCurrentSlideIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  const showNextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const updateSlideCounter = () => {
    return `${currentSlideIndex + 1}/${slides.length} слайдов`;
  };

  if (!isOpen) {
    return null;
  }
  return (
    <div className={styles.root}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <img
            className={styles.pic}
            alt={slides[currentSlideIndex].title}
            src={slides[currentSlideIndex].image}
          />
          <div className={styles.counter}>{updateSlideCounter()}</div>
          <div className={styles.arrow_group}>
            <div
              className={styles.arrow}
              onClick={showPreviousSlide}>
              Предыдущий
            </div>
            <div
              className={styles.arrow}
              onClick={showNextSlide}>
              Следующий
            </div>
          </div>
          <div
            className={styles.close}
            onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className={styles.title}>{slides[currentSlideIndex].title}</div>
      </div>
    </div>
  );
};

export default ModalKiosk;
