import React from 'react';
import styles from './StatisticBlock.module.css';
import Slide1 from '../../assets/SlidesStatistic/stat1.png';
import Round from '../../assets/SlidesStatistic/round.png';
import PeopleIcon from '@mui/icons-material/People';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import InfoIcon from '@mui/icons-material/Info';

const StatisticBlock = () => {
  return (
    <div className={styles.root}>
      <div className={styles.statistic_wrapper}>
        <div className={styles.left_wrapper}>
          <div className={styles.left_wrapper_title}>Статистика</div>
          <img
            src={Slide1}
            alt=""
          />
        </div>
        <div className={styles.right_wrapper}>
          <div className={styles.first}>
            <PeopleIcon className={styles.people_icon} />
            <div className={styles.first_item_title}>
              Информация по 2024 году
            </div>
            <div className={styles.first_item_group}>
              <div className={styles.first_item}>
                <div className={styles.first_item_icon}>1/</div>
                Количество обученных с использованием СДО более 100000 человек.
              </div>
              <div className={styles.first_item}>
                <div className={styles.first_item_icon}>2/</div>
                Количество обученных очно более 10000 человек в 30 локациях по
                всей стране.
              </div>
            </div>
            <div className={styles.first_item_outside}>
              <div className={styles.first_item_icon}>3/</div>В процессе
              обучения задействовано более 50 специалистов, из них 30 – штатные
              тренеры -преподаватели и 20 специалистов учебно-методического
              отдела
            </div>
          </div>
          <div className={styles.wrapper_info}>
            <div className={styles.second_title}>
              В Югорском учебном центре разработаны и реализуются более 1000
              учебных программ, в числе которых:
            </div>
            <div className={styles.second}>
              <div className={styles.second_block_left}>
                <div className={styles.second_block_left_item}>
                  <div className={styles.count}>+450</div>
                  <div className={styles.desc}>
                    Программ профессионального обучения
                  </div>
                </div>
                <div className={styles.second_block_left_item}>
                  <div className={styles.count}>+45</div>
                  <div className={styles.desc}>
                    Программ обучения по охране труда
                  </div>
                </div>
                <div className={styles.second_block_left_item}>
                  <div className={styles.count}>+200</div>
                  <div className={styles.desc}>
                    Образовательных программ целевого назначения
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.individual}>
            Все программы дорабатываются под нужды каждого заказчика
            <InfoIcon className={styles.info_icon} />
          </div>
          <div className={styles.third}>
            <div className={styles.third_block_left}>
              <img
                src={Round}
                alt=""
              />
            </div>
            <div className={styles.third_block_right}>
              Также Югорский учебный центр реализует корпоративные и
              обязательные программы на более чем 25 месторождениях нефти и газа
              России
              <FormatQuoteIcon className={styles.formatQuoteIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticBlock;
